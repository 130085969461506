import { useNavigate } from "react-router-dom";
import BackArrow from "../Resources/BackArrowNew.svg";
import { useEffect, useState } from "react";

export class Map {
  id = -1;
  name = "";
  credit = "";
  dataUpdated = 0;
  disabled = false;
  environment: Environment;
  gameMode: GameMode;
  hash = "";
  imageUrl = "";
  lastActive = "";
  link = "";
  new = false;
  version = -1;
  order = -1;

  constructor(json: any, num: number) {
    this.id = json.id;
    this.name = json.name;
    this.credit = json.credit;
    this.dataUpdated = json.dataUpdated;
    this.disabled = json.disabled;
    this.environment = new Environment(json.environment);
    this.gameMode = new GameMode(json.gameMode);
    this.hash = json.hash;
    this.imageUrl = json.imageUrl;
    this.lastActive = json.lastActive;
    this.link = json.link;
    this.new = json.new;
    this.version = json.version;
    this.order = num;
  }
}

export class Environment {
  name = "";
  id = -1;
  hash = "";
  imageUrl = "";
  version = -1;

  constructor(json: any) {
    this.name = json.name ?? "No Name";
    this.id = json.id;
    this.hash = json.hash;
    this.imageUrl = json.imageUrl;
    this.version = json.version;
  }
}

export class GameMode {
  bgColor = "";
  color = "";
  hash = "";
  id = -1;
  imageUrl = "";
  link = "";
  name = "";
  version = -1;

  constructor(json: any) {
    this.bgColor = json.bgColor;
    this.color = json.color;
    this.hash = json.hash;
    this.id = json.id;
    this.imageUrl = json.imageUrl;
    this.link = json.link;
    this.name = json.name;
    this.version = json.version;
  }
}

export default function MapsListPage() {
  const [maps, setMaps] = useState<Map[]>([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  async function fetchMaps() {
    try {
      const response = await fetch(`https://json-api-rust.vercel.app/api/maps`);
      if(!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const mapJson = await response.json();

      let mapsArr = [];
      for(let i = 0; i < mapJson.length; i++) {
        mapsArr.push(new Map(mapJson[i], mapJson[i].id))
      }
      setMaps(mapsArr);
    } catch (error) {
      console.error('Error fetching map:', error);
      // Handle the error, for example, by displaying an error message in the UI
    }
  }

  function mapSelected(id: number) {
    navigate("../maps/" + id);
  }

  function getFilteredMaps() {
    return maps.filter(m => m.name.toLowerCase().includes(search.toLowerCase()) || m.environment.name.toLowerCase().includes(search.toLowerCase()) || m.gameMode.name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(() => {
    fetchMaps();
  }, []);

  return (
    <div className="maps-list-page">
      <div className="top-header">
        <div className="title">
          <img className="back-arrow" onClick={() => navigate("/")} src={BackArrow} alt="Back" />
          <div className="divider" />
            Maps
        </div>
        <div className="search-map">
          <input type="text" name="Search" placeholder="Search..."className="search-bar" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>

      <div className="main-body">

        <div className="brawlers-list">
          {getFilteredMaps().map((map) => 
            <MapCardView map={map} onSelect={mapSelected} key={map.id}/>
          )}
        </div>
      </div>

    </div>
  )
}

function MapCardView({map, onSelect}: {map: Map, onSelect: (id: number) => void}) {
  return (
    <div className="card" style={{boxShadow: "0px 0px 20px 4px " + map.gameMode.color}} onClick={() => onSelect(map.id)}>
      <div className="top">
        <img src={map.imageUrl} alt="Pin" className="little-brawler-icon" />
        <div className="name" style={{color: map.gameMode.color}}>{map.name}</div>
      </div>
      <div className="description">{map.environment.name}</div>
    </div>
  )
}