import { useNavigate, useParams } from "react-router-dom"
import BackArrow from "../Resources/BackArrowNew.svg";
import { useEffect, useState } from "react";
import { Map } from "./MapsListPage";

export default function MapPage() {
  const {id} = useParams();
  const [map, setMap] = useState<Map>();
  const navigate = useNavigate();

  async function fetchMap(id: number) {
    try {
      const response = await fetch(`https://json-api-rust.vercel.app/api/maps/${id}`);
      if(!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const mapJson = await response.json();

      setMap(new Map(mapJson, id));
    } catch (error) {
      console.error('Error fetching map:', error);
      // Handle the error, for example, by displaying an error message in the UI
    }
  }

  useEffect(() => {
    if(id) {
      fetchMap(parseInt(id));
    }
  }, [id]);

  return (
    <div className="map">
      <div className="title">
        <img className="back-arrow" onClick={() => navigate("/maps")} src={BackArrow} alt="Back" />
        <div className="divider"/>
        {map ? map.name : id}
      </div>

      {map && <div className="main-body">
        <img className="mini-map" src={map.imageUrl} alt={map.name + " Image"} />

        <div className="brawler-description">

          <div>
            <p className="detail-name">Environment: </p>{map.environment.name}
            <img className="mini-map" src={map.environment.imageUrl} alt={map.name + " Image"} />
          </div>

          <div>
            <p className="detail-name">Game mode: </p>
            <p className="detail-description" style={{color: map.gameMode.color}}>{map.gameMode.name}</p>
            <img className="game-mode-icon" style={{filter: "drop-shadow(0 0 .6em " + map.gameMode.bgColor + ")"}} src={map.gameMode.imageUrl} alt={map.name + " Image"} />
          </div>

        </div>
        
      </div>}
    </div>
  )
}