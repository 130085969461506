import './App.css';
import { BrowserRouter } from 'react-router-dom';
import RoutesConfig from './RoutesConfig';
import { NavigationProvider } from './NavigationContext';


function App() {
  return (
    <BrowserRouter>
      <NavigationProvider>
        <RoutesConfig />
      </NavigationProvider>
    </BrowserRouter>
  );
}

export default App;
