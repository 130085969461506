import { useState } from "react"
import { useNavigate } from "react-router-dom";
import BackArrow from '../Resources/BackArrowNew.svg';
import BackButton from "../Components/BackButton";

let playersSearched: string[] = (process.env.NODE_ENV === "development") ? ["2QPQUOVUQ", "ggyop8v8c"] : [];

export default function FindPlayerPage() {
  const navigate = useNavigate();
  const [playerId, setPlayerId] = useState("");

  function findPlayer(tag: string) {
    if(!playersSearched.includes(tag)) {
      playersSearched.push(tag);
    }
    navigate(tag || "2QPQUOVUQ");
  }

  return (
    <div className="find-player-page">
      <div className="title">
        <BackButton />
        <div className="divider"/>
        FindPlayerPage
      </div>
      Player ID: 
      <input value={playerId} onChange={e => setPlayerId(e.target.value)} />
      <button onClick={() => findPlayer(playerId)}>Find</button>
      <div>
        {playersSearched.map(tag =>
          <button key={tag} onClick={() => findPlayer(tag)}>
            {tag}
          </button>
        )}
      </div>
    </div>
  )
}