import BackArrow from '../Resources/BackArrowNew.svg'
import { useNavigation } from '../NavigationContext';

export default function BackButton() {
  const { goBack } = useNavigation();

  return (
    <img className="back-arrow" onClick={goBack} src={BackArrow} alt="Back" />
  );
};
