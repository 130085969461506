import { useNavigate, useParams } from "react-router-dom";
import { Brawler, Gadget, StarPower } from "./BrawlersListPage";
import { useEffect, useState } from "react";
import BackArrow from "../Resources/BackArrowNew.svg";
import BackButton from "../Components/BackButton";
import { fetchBrawler } from "../server/server";

export default function BrawlerPage() {
  const { id } = useParams();
  const [brawler, setBrawler] = useState<Brawler>();
  const navigate = useNavigate();

  async function getBrawler(id: number) {
    setBrawler(await fetchBrawler(id));
  }

  useEffect(() => {
    if(id) {
      getBrawler(parseInt(id));
    }
  }, [id]);

  return (
    <div className="brawler">
      <div className="title">
        {/* <button onClick={goBack}>Go Back</button> */}
        <BackButton />
        <div className="divider" />
        {brawler && <img className="brawler-icon" src={brawler.imgUrl2} alt="Brawler2" />}
        {brawler ? brawler.name : id}
      </div>

      {brawler && <div className="main-body">

        <img className="brawler-pin" src={brawler.imgUrl3} alt={brawler.name + "Pin"} />
        <div className="brawler-description">
          {brawler.description}
        </div>

        <StarPowersView starPowers={brawler.starPowers} />
        <GadgetsView gadgets={brawler.gadgets} />
        <img src="https://prasaadjg.github.io/json-host/brawl/fankit/brawlers/sprout/pins/aquamarine.png" alt="hi" />
        <img src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoic3VwZXJjZWxsXC9maWxlXC9XenVvenFYNTVoN1c2Skw0RGJoYy5wbmcifQ:supercell:_hV2sGF0a-2yomZrXp6BPSfyUO5D8bDXWiFFOil5nAk?width=2400" alt="" />
      </div>}
    </div>
  )
}

function StarPowersView({starPowers}: {starPowers: StarPower[]}) {
  const [selectedSP, setSelectedSP] = useState(0);

  function SP() {
    return starPowers[selectedSP];
  }

  function imageClassName(num: number) {
    return "star-power-image" + (selectedSP === num ? " selected" : "");
  }

  return (
    <div className="star-powers-view">
      <div className="star-powers">
        {starPowers.map((starPower, i) => (
          <img key={i} className={imageClassName(i)} onClick={() => setSelectedSP(i)} src={starPower.imageUrl} alt={starPower.name}/>
        ))}
      </div>
      <div className="details">
        <div className="name">{SP().name}</div>
        <div className="description" dangerouslySetInnerHTML={{__html: SP().descriptionHtml}} />
      </div>
    </div>
  )
}

function GadgetsView({gadgets}: {gadgets: StarPower[]}) {
  const [selectedGadget, setSelectedGadget] = useState(0);

  function gadget() {
    return gadgets[selectedGadget];
  }

  function imageClassName(num: number) {
    return "gadget-image" + (selectedGadget === num ? " selected" : "");
  }

  return (
    <div className="gadgets-view">
      <div className="gadgets">
        {gadgets.map((gadget, i) => (
          <img key={i} className={imageClassName(i)} onClick={() => setSelectedGadget(i)} src={gadget.imageUrl} alt={gadget.name}/>
        ))}
      </div>
      <div className="details">
        <div className="name">{gadget().name}</div>
        <div className="description" dangerouslySetInnerHTML={{__html: gadget().descriptionHtml}} />
      </div>
    </div>
  )
}


function StarPowerView({starPower, num}: {starPower: StarPower, num: number}) {
  return(
    <div className="star-power">
      <input id={"starPowerCheckbox" + num} type="checkbox" name="menu"/>
      <label htmlFor={"starPowerCheckbox" + num} className="star-power-label">
      <img className="star-power-image" src={starPower.imageUrl} alt={starPower.name}/>
      </label>
      <div className="details">
        <div className="name">{starPower.name}</div>
        {starPower.description}
      </div>
    </div>
  )
}

function GadgetView({gadget, num}: {gadget: Gadget, num: number}) {
  return(
    <div className="gadget">
      <input id={"gadgetCheckbox" + num} type="checkbox" name="menu"/>
      <label htmlFor={"gadgetCheckbox" + num} className="gadget-label">
      <img className="gadget-image" src={gadget.imageUrl} alt={gadget.name}/>
      </label>
      <div className="details">
        <div className="name">{gadget.name}</div>
        {gadget.description}
      </div>
    </div>
  )
}