import { useParams } from "react-router-dom"

export default function ErrorPage() {
  const {error} = useParams();

  return (
    <div>
      <div className="title">Error</div>
      Oops! Looks like <p style={{textDecoration: "underline", display: "inline"}}>{error}</p> isn't a valid site!! Try again LLLLLL
    </div>
  )
}