import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIcons from "react-loading-icons";
import SproutPin from "../Resources/Sprout Pin.webp";
import BackButton from "../Components/BackButton";
import { Brawler } from "./BrawlersListPage";
import { fetchBrawlers, fetchEvents } from "../server/server";
import styled from 'styled-components';

class Player {
  tag = "";
  name = "";
  nameColor = "";
  iconId = -1;
  trophies = -1;
  highestTrophies = -1;
  expLevel = -1;
  expPoints = -1;
  highestPowerPlayPoints = -1;
  isQualifiedFromChampionshipChallenge = false;
  ThreeVsThreeVictories = -1;
  soloVictories = -1;
  duoVictories = -1;
  bestRoboRumbleTime = -1;
  bestTimeAsBigBrawler = -1;
  brawlers: PlayerBrawler[];
  club: PlayerClub;

  constructor(json: any) {
    this.tag = json.tag;
    this.name = json.name;
    this.nameColor = json.nameColor;
    this.iconId = json.icon.id;
    this.trophies = json.trophies;
    this.highestTrophies = json.highestTrophies;
    this.expLevel = json.expLevel;
    this.expPoints = json.expPoints;
    this.highestPowerPlayPoints = json.highestPowerPlayPoints;
    this.isQualifiedFromChampionshipChallenge =
      json.isQualifiedFromChampionshipChallenge;
    this.ThreeVsThreeVictories = json["3vs3Victories"];
    this.soloVictories = json.soloVictories;
    this.duoVictories = json.duoVictories;
    this.bestRoboRumbleTime = json.bestRoboRumbleTime;
    this.bestTimeAsBigBrawler = json.bestTimeAsBigBrawler;
    this.club = new PlayerClub(json.club);

    this.brawlers = [];
    for (let i = 0; i < json.brawlers.length; i++) {
      this.brawlers.push(new PlayerBrawler(json.brawlers[i]));
    }
  }
}

class PlayerClub {
  name = "";
  tag = "";

  constructor(json: any) {
    this.name = json.name;
    this.tag = json.tag;
  }
}

class PlayerBrawler {
  id = -1;
  name = "";
  power = -1;
  rank = -1;
  trophies = -1;
  rarityColor = "";

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.power = json.power;
    this.rank = json.rank;
    this.trophies = json.trophies;
  }
}

export default function PlayerPage() {
  const { tag } = useParams();
  const navigate = useNavigate();
  const [player, setPlayer] = useState<Player>();
  const [brawlers, setBrawlers] = useState<Brawler[]>([]);
  let playerData: Player;

  async function fetchPlayer(tag: string) {
    try {
      const response = await fetch(
        `https://json-api-rust.vercel.app/api/brawl/players/${tag}`
      );
      if (!response.ok) {
        throw new Error("Error reading response: " + response.status);
      }
      const playerJson = await response.json();

      setPlayer(new Player(playerJson));
      playerData = new Player(playerJson);

    } catch (error) {
      console.log("Error fetching player data: " + error);
    }
    getBrawlers();
  }

  async function getBrawlers() {
    const returnBrawlers = await fetchBrawlers()
    let brawlersMap = new Map<number, Brawler>();
    setBrawlers(returnBrawlers);

    for(let i = 0; i < returnBrawlers.length; i++) {
      brawlersMap.set(returnBrawlers[i].id, returnBrawlers[i]);
    }

    
    for(let i = 0; i < playerData.brawlers.length; i++) {
      if(brawlersMap.has(playerData.brawlers[i].id)) {
        playerData.brawlers[i].rarityColor = brawlersMap.get(playerData.brawlers[i].id)?.rarityColor ?? "";
      }
    }
    setPlayer(playerData);
  }

  useEffect(() => {
    fetchPlayer(tag!);
    fetchEvents();
  }, [tag]);

  function brawlerSelected(id: number) {
    navigate(`/brawlers/${id}`);
  }

  return (
    <div>
      <div className="title">
        <BackButton />
        <div className="divider"/>
        {player ? player.name : "PlayerPage"}
      </div>

      {player && 
        <div>
          {player.name + " - " + 
          player.trophies.toLocaleString()}

          <PlayerTable player={player} />
          <BrawlersTable brawlers={player.brawlers} brawlerSelected={brawlerSelected} />
        </div>
      }
      {!player && 
        <div>
          Finding Player...

          <img className="loading-icon" src={SproutPin} />
          <LoadingIcons.BallTriangle className="loading Icon" />
        </div>
      }
    </div>
  );
}

function PlayerBrawlerCard() {
  return (
    <div className="player-brawler-card">
      <table>
        
      </table>
    </div>
  )
}

const PlayerTable = ({ player }: {player: Player}) => {

  return (
    <div>
      <h1>Player Data</h1>
      <table>
        <thead>
          <tr>
            <th>Property</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Tag</td><td>{player.tag}</td></tr>
          <tr><td>Name</td><td>{player.name}</td></tr>
          <tr><td>Name Color</td><td>{player.nameColor}</td></tr>
          <tr><td>Icon ID</td><td>{player.iconId}</td></tr>
          <tr><td>Trophies</td><td>{player.trophies}</td></tr>
          <tr><td>Highest Trophies</td><td>{player.highestTrophies}</td></tr>
          <tr><td>Experience Level</td><td>{player.expLevel}</td></tr>
          <tr><td>Experience Points</td><td>{player.expPoints}</td></tr>
          <tr><td>Highest Power Play Points</td><td>{player.highestPowerPlayPoints}</td></tr>
          <tr><td>Qualified from Championship Challenge</td><td>{player.isQualifiedFromChampionshipChallenge.toString()}</td></tr>
          <tr><td>3vs3 Victories</td><td>{player.ThreeVsThreeVictories}</td></tr>
          <tr><td>Solo Victories</td><td>{player.soloVictories}</td></tr>
          <tr><td>Duo Victories</td><td>{player.duoVictories}</td></tr>
          <tr><td>Best Robo Rumble Time</td><td>{player.bestRoboRumbleTime}</td></tr>
          <tr><td>Best Time as Big Brawler</td><td>{player.bestTimeAsBigBrawler}</td></tr>
          <tr><td>Club Name</td><td>{player.club.name}</td></tr>
          <tr><td>Club Tag</td><td>{player.club.tag}</td></tr>
        </tbody>
      </table>
    </div>
  );
}

const BrawlersTable = ({ brawlers, brawlerSelected }: {brawlers: PlayerBrawler[], brawlerSelected: (id: number) => void}) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  function sortedBrawlers() {
    return brawlers.sort((a, b) => b.trophies - a.trophies);
  }

  function boxShadow(brawler: PlayerBrawler) {
    return brawler.rarityColor === "" ? "none" : "0px 0px 20px 4px " + brawler.rarityColor;
  }

  return (
    <div>
      <h1>Player Brawlers</h1>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Power</th>
            <th>Rank</th>
            <th>Trophies</th>
          </tr>
        </thead>
        <tbody>
          {sortedBrawlers().map((brawler, index) => (
            <tr className="player-brawler-row" style={{
              boxShadow: hoveredRow === index ? boxShadow(brawler) : 'none',
            }} key={index} onClick={() => brawlerSelected(brawler.id)} 
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(null)}>
              <td>{brawler.name}</td>
              <td>{brawler.power}</td>
              <td>{brawler.rank}</td>
              <td>{brawler.trophies}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};