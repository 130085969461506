import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import BackArrow from "../Resources/BackArrowNew.svg";
import BackButton from "../Components/BackButton";
import { fetchBrawlers } from "../server/server";

export class Brawler {
  id = 0;
  name = "";
  classId = 0;
  className = "";
  description = "";
  descriptionHTML = "";
  gadgets: Gadget[] = [];
  imgUrl = "";
  imgUrl2 = "";
  imgUrl3 = "";
  link = "";
  rarityId = 0;
  rarityName = "";
  rarityColor = "";
  released = true;
  starPowers: StarPower[] = [];
  unlock = true;
  version = 0;
  releaseOrder = -1;


  constructor(json: any, releaseOrder: number) {
    this.id = json.id;
    this.name = json.name;
    this.classId = json.class.id;
    this.className = json.class.name;
    this.description = json.description;
    this.descriptionHTML = json.descriptionHTML;
    this.releaseOrder = releaseOrder;
    this.imgUrl = json.imageUrl;
    this.imgUrl2 = json.imageUrl2;
    this.imgUrl3 = json.imageUrl3;
    this.link = json.link;
    this.rarityId = json.rarity.id;
    this.rarityName = json.rarity.name;
    this.rarityColor = json.rarity.color;
    this.released = json.released;
    this.unlock = json.unlock;
    this.version = json.version;

    this.gadgets = [];
    for(let i = 0; i < json.gadgets.length; i++) {
      this.gadgets.push(new Gadget(json.gadgets[i]))
    }

    this.starPowers = [];
    for(let i = 0; i < json.starPowers.length; i++) {
      this.starPowers.push(new StarPower(json.starPowers[i]))
    }
  }
}

export class Gadget {
  id = 0;
  name = "";
  description = "";
  descriptionHtml = "";
  imageUrl = "";
  released = "";
  version = 0;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.descriptionHtml = json.descriptionHtml;
    this.imageUrl = json.imageUrl;
    this.released = json.released;
    this.version = json.version;
  }
}

export class StarPower {
  id = 0;
  name = "";
  description = "";
  descriptionHtml = "";
  imageUrl = "";
  released = "";
  version = 0;

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.descriptionHtml = json.descriptionHtml;
    this.imageUrl = json.imageUrl;
    this.released = json.released;
    this.version = json.version;
  }
}


export default function BrawlersListPage() {
  const [brawlers, setBrawlers] = useState<Brawler[]>([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  
  async function getBrawlers() {
    setBrawlers(await fetchBrawlers());
  }

  function brawlerSelected(id: number) {
    navigate("../brawlers/" + id);
  }

  function sortBrawlers(search: string, a: Brawler, b: Brawler): number {
    if(search === ""){
      if(a.releaseOrder > b.releaseOrder) {
        return 1;
      } else if(a.releaseOrder < b.releaseOrder) {
        return -1;
      } else {
        return 0;
      }
    }

    const searchLower = search.toLowerCase();
    const aNameStartsWithSearch = a.name.toLowerCase().startsWith(searchLower);
    const bNameStartsWithSearch = b.name.toLowerCase().startsWith(searchLower);
    const aRarityStartsWithSearch = a.rarityName.toLowerCase().startsWith(searchLower);
    const bRarityStartsWithSearch = b.rarityName.toLowerCase().startsWith(searchLower);

    if (aNameStartsWithSearch && !bNameStartsWithSearch) {
      return -1;
    } else if (!aNameStartsWithSearch && bNameStartsWithSearch) {
      return 1;
    } else if (aNameStartsWithSearch && bNameStartsWithSearch) {
      return a.name.localeCompare(b.name);
    } else if (aRarityStartsWithSearch && !bRarityStartsWithSearch) {
      return -1;
    } else if (!aRarityStartsWithSearch && bRarityStartsWithSearch) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }

  function getFilteredBrawlers() {
    return brawlers.filter(b => b.name.toLowerCase().includes(search.toLowerCase()) || b.rarityName.toLowerCase().includes(search.toLowerCase())).sort((a, b) => sortBrawlers(search, a, b));
  }

  useEffect(() => {
    getBrawlers();
  }, []);

  return (
    <div className="brawlers-list-page">
      <div className="top-header">
        <div className="title">
          <BackButton />
          <div className="divider" />
            Brawlers
        </div>
        <div className="search-brawler">
          <input type="text" name="Search" placeholder="Search..."className="search-bar" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>

      <div className="main-body">

        <div className="brawlers-list">
          {getFilteredBrawlers().map((brawler) => 
            <BrawlerCardView brawler={brawler} onSelect={brawlerSelected} key={brawler.id}/>
          )}
          <ScrollToTopButton targetRef={useRef<HTMLDivElement>(null)}/>
        </div>
      </div>
    </div>
  )
}

function BrawlerCardView({brawler, onSelect}: {brawler: Brawler, onSelect: (id: number) => void}) {
  return (
    <div className="card" style={{boxShadow: "0px 0px 20px 4px " + brawler.rarityColor}} onClick={() => onSelect(brawler.id)}>
      <div className="top">
        <img src={brawler.imgUrl3} alt="Pin" className="little-brawler-icon" />
        <div className="name" style={{color: brawler.rarityColor}}>{brawler.name}</div>
      </div>
      <div className="description">{brawler.description}</div>
    </div>
  )
}

function ScrollToTopButton({ targetRef }: { targetRef: React.RefObject<HTMLDivElement> }) {
  const [showButton, setShowButton] = useState(false);

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = () => setShowButton(getShowButton());

  function scrollUpTimer() {
    window.setTimeout(scrollHalfToTop, 50);
    window.setTimeout(scrollHalfToTop, 100);
    window.setTimeout(scrollToTop, 150);
  }


  function scrollHalfToTop(position: number) {
    document.body.scrollTop /= 2; // For Safari
    document.documentElement.scrollTop /= 2; // For Chrome, Firefox, IE and Opera
  }

  // When the user clicks on the button, scroll to the top of the document
  function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  function getShowButton() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      return true;
    } else {
      return false;
    }
  }


  return (
    <button onClick={scrollToTop} className={"scroll-to-top " + (showButton ? "show" : "")} title="Go to top">Top</button>
  )
}