import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationContextType {
  goBack: () => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

const NAVIGATION_STACK_KEY = 'navigationStack';

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [navigationStack, setNavigationStack] = useState<string[]>(() => {
    const storedStack = localStorage.getItem(NAVIGATION_STACK_KEY);
    return storedStack ? JSON.parse(storedStack) : [];
  });

  useEffect(() => {
    const newPath = location.pathname;
    setNavigationStack((prevStack) => {
      if (prevStack.length === 0 || prevStack[prevStack.length - 1] !== newPath) {
        const updatedStack = [...prevStack, newPath];
        localStorage.setItem(NAVIGATION_STACK_KEY, JSON.stringify(updatedStack));
        return updatedStack;
      }
      return prevStack;
    });
  }, [location]);

  const goBack = () => {
    if (navigationStack.length > 1) {
      const newStack = [...navigationStack];
      newStack.pop(); // Remove the current path
      const lastPath = newStack[newStack.length - 1];
      setNavigationStack(newStack);
      localStorage.setItem(NAVIGATION_STACK_KEY, JSON.stringify(newStack));
      navigate(lastPath);
    } else {
      navigate('/'); // Fallback to home if no history
    }
  };

  return (
    <NavigationContext.Provider value={{ goBack }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
