import { Routes, Route } from "react-router-dom";
import BrawlerPage from "./Pages/BrawlerPage";
import ErrorPage from "./Pages/ErrorPage";
import BrawlersListPage from "./Pages/BrawlersListPage";
import LandingPage from "./Pages/LandingPage";
import MapsListPage from "./Pages/MapsListPage";
import MapPage from "./Pages/MapPage";
import PlayerPage from "./Pages/PlayerPage";
import FindPlayerPage from "./Pages/FindPlayerPage";
// import TestNavigationPage from "./Pages/TestNavigatePage";

export default function RoutesConfig() {
  
  return (
    <Routes>
      <Route path="/" element={<LandingPage/>} />
      <Route path="/brawlers" element={<BrawlersListPage/>} />
      <Route path="/brawlers/:id" element={<BrawlerPage/>} />
      <Route path="/maps" element={<MapsListPage/>} />
      <Route path="/maps/:id" element={<MapPage/>} />
      <Route path="/player" element={<FindPlayerPage/>} />
      <Route path="/player/:tag" element={<PlayerPage/>} />
      {/* <Route path="/tn" element={<TestNavigationPage/>} /> */}
      <Route path="/:error" element={<ErrorPage/>} />
    </Routes>
  )
}