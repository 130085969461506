import { useNavigate } from "react-router-dom";
import sprout_pin from "../Resources/Sprout Pin.webp";
import map_image from "../Resources/15000005.webp";
import LoadingIcons from "react-loading-icons";

export default function LandingPage() {
  const navigate = useNavigate();
  const sproutImageLinks = ["https://prasaadjg.github.io/json-host/brawl/fankit/brawlers/sprout/pins/aquamarine.png", sprout_pin];

  function getSproutImageLink() {
    const index = Math.floor(Math.random() * sproutImageLinks.length);
    return(sproutImageLinks[index]);
  }

  return (
    <div className="landing-page">
      <div className="title">
        Welcome to Brawl Notes!!
      </div>
      
      <div className="main-body">
        <div className="page-link" onClick={() => navigate('/brawlers')}>
          <div className="name">Brawlers</div>
          <img className="page-link-img" src={getSproutImageLink()} alt="" />
        </div>

        <div className="page-link" onClick={() => navigate('/maps')}>
          <div className="name">Maps</div>
          <img className="page-link-img map" src={map_image} alt="" />
        </div>

        <div className="page-link" onClick={() => navigate('/player')}>
          <div className="name">Player</div>
          <img className="page-link-img player" src={map_image} alt="" />
        </div>

      </div>

    </div>
  )
}