import { Brawler } from "../Pages/BrawlersListPage";
import { Map } from "../Pages/MapsListPage";
import localBrawlerData from '../Reference/brawlers.json';

export class Event {
  endTime = "";
  map = new Map("", -1);
  modifier = null;
  predicted = false;
  reward = 0;
  slot = new EventSlot("");
  startTime = "";

  constructor(json: any, num: number) {
    console.log("TESTING 1");
    console.log(json.map);
    this.endTime = json.endTime;
    this.map = new Map(json.map, json.map.id);
    this.modifier = json.modifier;
    this.predicted = json.predicted;
    this.reward = json.reward;
    this.slot = new EventSlot(json.slot);
    this.startTime = json.startTime;
  }
}

class SlotMap {


  constructor(json: any) {

  }
}

export class EventSlot {
  background = null;
  emoji = "";
  hash = "";
  hideForSlot = 0;
  hidable = false;
  id = 0;
  listAlone = false;
  name = "";

  constructor(json: any) {
    this.background = json.background;
    this.emoji = json.emoji;
    this.hash = json.hash;
    this.hideForSlot = json.hideForSlot;
    this.hidable = json.hidable;
    this.id = json.id;
    this.listAlone = json.listAlone;
    this.name = json.name;
  }
}

export async function fetchBrawler(id: number) {
  try {
    const response = await fetch(`https://json-api-rust.vercel.app/api/brawlers/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const brawlerJson = await response.json();
    return new Brawler(brawlerJson, id);
  } catch (error) {
    console.error('Error fetching brawler:', error);
    // Handle the error, for example, by displaying an error message in the UI
  }
}

export async function fetchBrawlers() {
  let brawlersJson: any;
  try {
    const response = await fetch("https://json-api-rust.vercel.app/api/brawlers");
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    brawlersJson = await response.json();
  } catch {
    brawlersJson = localBrawlerData.list;
  }

  let brawlerArr = [];
  let linkArray = [];
  for(let i = 0; i < brawlersJson.length; i++) {
    brawlerArr.push(new Brawler(brawlersJson[i], i));
    linkArray.push({name: brawlerArr[brawlerArr.length - 1].name, id: brawlerArr[brawlerArr.length - 1].id});
  }
  return brawlerArr;
}

export async function fetchEvents() {
  let eventsJson: any;
  try {
    const response = await fetch("https://api.brawlify.com/v1/events");
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    eventsJson = await response.json();
  } catch {
    eventsJson = [];
  }

  console.log(eventsJson);

  let activeEventsArr = [];
  let upcomingEventsArr = [];
  let linkArray = [];
  for(let i = 0; i < eventsJson.active.length; i++) {
    console.log(eventsJson.active[i].map.environment);
    activeEventsArr.push(new Event(eventsJson.active[i], i));
    // linkArray.push({name: activeEventsArr[activeEventsArr.length - 1].name, id: activeEventsArr[activeEventsArr.length - 1].id});
  }
  for(let i = 0; i < eventsJson.upcoming.length; i++) {
    upcomingEventsArr.push(new Event(eventsJson.upcoming[i], i));
    // linkArray.push({name: upcomingEventsArr[upcomingEventsArr.length - 1].name, id: upcomingEventsArr[upcomingEventsArr.length - 1].id});
  }
  return {active: activeEventsArr, upcoming: upcomingEventsArr};
}